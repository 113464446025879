import { AfterViewInit, Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService } from 'primeng/primeng';
import { Setup } from './configuracoes/setup/setup.model';
import { SetupService } from './configuracoes/setup/setup.service';
import { AuthService } from './shared/auth/auth.service';
import { KEY_CODE } from './shared/constantes/key-codes';
import { GlobalsVariablesService } from './shared/service/global-variables.service';
import { MessageService } from './shared/service/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.css',
    './app.responsive.css'
  ],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit, AfterViewInit {
  teste: String;
  closeSession = false;
  private pageEnter: Boolean = false;
  pagesActive: any;
  refresh: number = 0;
  refresh_ondestroy: Number;
  pagesActiveStr;
  rotaAnterior;
  rotaAtual;
  keypressCode;
  tipoObjeto: string = 'E'; //TipoObjeto.EQUIPAMENTO
  windowSize: Number;
  usuarioLogado = Object();
  private limitePaginas: number = 5;
  private user: string = '';
  private login = {
    login: '',
    password: ''
  };
  public setupModel: Setup = new Setup();
  public subMenus: any;
  public subMenuAnterior: any = null;
  public ultimaListaClicada: any;
  public ultimoMenuClicado: any;

  constructor(
    public _router: Router,
    public activatedRoute: ActivatedRoute,
    public _messageService: MessageService,
    public authService: AuthService,
    public _confirmationService: ConfirmationService,
    public globalsVariablesService: GlobalsVariablesService,
    public cookieService: CookieService,
    public _setupService: SetupService,
  ) {
  }

  ngOnInit() {

    this.abrirMenus();
    this.verificarRotas();
    this.saveCurrentRoute();
    this.buscarSubMenus();
  }

  ngAfterViewInit() { }

  @HostListener('window:load', ['$event'])
  onload(event) {
    this.refresh = performance.navigation.type;
    this.enter();
  }

  abrirMenus(): void {
    let interval = setInterval(
      () => {
        let arraySubMenus = document.querySelectorAll('.treeview')
        let array = Array.prototype.slice.call(arraySubMenus);

        array.forEach(elemento => {
          elemento.addEventListener('click', (evt) => {

            let elementoHTML = <HTMLElement>elemento;
            let subMenu = elementoHTML.childNodes[1] as HTMLElement;

            if (evt.target.tagName == 'I' && subMenu.classList.contains('display-block')) {
              subMenu.classList.remove('display-block');
              this.ultimoMenuClicado.classList.remove('display-block');
              this.ultimaListaClicada = elementoHTML;
              this.ultimoMenuClicado = subMenu;
              return;
            }


            subMenu.classList.add('display-block');

            if (this.ultimoMenuClicado == undefined)
              this.ultimoMenuClicado = subMenu;

            if (elementoHTML === this.ultimaListaClicada && subMenu.classList.contains('display-block'))
              return;

            if (elementoHTML != this.ultimaListaClicada) {
              this.ultimoMenuClicado.classList.remove('display-block');
            }

            this.ultimaListaClicada = elementoHTML;
            this.ultimoMenuClicado = subMenu;
          });
        })
        clearInterval(interval);

      },
      500
    );

  }



  private enter() {

    this.pagesActiveStr = localStorage.getItem("PagesActive");
    this.pagesActive = parseInt(this.pagesActiveStr);

    if (this.refresh != 1) {
      this.newPage();
      this.verificarAutenticacao();
      this.pageEnter = true;
    } else {
      if (this.refresh == 1) {
        this.refreshPage();
        this.pageEnter = true;
      }
    }

  }

  private newPage() {
    if (this.pagesActive == null || this.pagesActive == 0 || isNaN(this.pagesActive)) {
      //primeiro acesso - primeira aba ativa
      this.pagesActive = 1;
      this.pagesActiveStr = this.pagesActive;
      this.pagesActiveStr.toString();
      sessionStorage.setItem("firstPage", "yes");
    } else {
      //Pega o histórico de abas abertas e soma +1 para gravar a atividade da aba atual
      this.pagesActive++;
      this.pagesActiveStr = this.pagesActive.toString();
    }
    this.limitPage(this.pagesActive);
    //atualiza o saldo de abas ativas no LOCALSTORAGE
    localStorage.setItem("PagesActive", this.pagesActive);
  }

  private refreshPage() {
    this.listarSetups();
    this.pagesActive++;
    this.limitPage(this.pagesActive);

    if (isNaN(this.pagesActive)) {
      this.pagesActive = 1;
    }

    localStorage.setItem("PagesActive", this.pagesActive);

    //RECUPERA OS DADOS DE PERMISSAO DO USUÁRIO QUANDO DADO UM REFRESH 
    let usuarioLogado = sessionStorage.getItem("usuarioLogado");

    if (usuarioLogado != null && usuarioLogado != "{}") {
      sessionStorage.removeItem("usuarioLogado");
      localStorage.removeItem("usuarioLogado");
      //MONTANDO STORAGE COM AS PERMISSOES
      let authToken = sessionStorage.getItem("authToken");
      if (authToken != null) {
        localStorage.setItem("authToken", authToken);
        this.usuarioLogado = JSON.parse(usuarioLogado);
        this.user = this.cookieService.get('user');
        if (this.user == '') {
          this.login.login = this.usuarioLogado.login;
          this.login.password = this.usuarioLogado.senha;
          this.cookieService.set('user', JSON.stringify(this.login), 365);
        }
        this.globalsVariablesService.setGlobalsVariables(this.usuarioLogado);
      } else {
        authToken = localStorage.getItem("authToken");
        if (authToken != null) {
          sessionStorage.setItem("authToken", authToken);
          this.usuarioLogado = JSON.parse(usuarioLogado);
          this.user = this.cookieService.get('user');
          if (this.user == '') {
            this.login.login = this.usuarioLogado.login;
            this.login.password = this.usuarioLogado.senha;
            this.cookieService.set('user', JSON.stringify(this.login), 365);
          }
          this.globalsVariablesService.setGlobalsVariables(this.usuarioLogado);
        } else {
          this.resetSessao();
        }
      }
    } else {
      this.verificarAutenticacao();
    }

    //QUANDO O REFRESH FOR FEITO ATRAVÉS DO BOTÃO ATUALIZAR DA PÁGINA
    if (this.pagesActiveStr == null) {
      localStorage.setItem("PagesActive", "1");
      let rotaAnterior = this.getRoute();
      if (rotaAnterior == "/login") {
        this.resetSessao();
      } else {
        //QUANDO REFRESH FEITO EM ALGUMA OUTRA PÁGINA ATIVA
        if (rotaAnterior == null) {
          this._router.navigate(['/dashboard']);
        }
      }
    }
  }

  private verificarAutenticacao() {
    if (this.pagesActive == null || this.pagesActive == 0 || isNaN(this.pagesActive)) {
      this.pagesActive = 1;
      this.pagesActiveStr = this.pagesActive;
      this.pagesActiveStr.toString();
    }
    this.limitPage(this.pagesActive);
    if (this.authService.isLoggedIn == false) {
      this.resetSessao();
    } else {
      if (this.globalsVariablesService.getPermissaoSuccess == false) {
        this.recuperarSessao();
      }
    }
  }

  private verificarLogin() {
    let validAuth = true;
    let auth: any = localStorage.getItem("authToken");
    if (auth == null || auth == '') validAuth = false;
    if (this._router.url == '/login') {
      if (this.authService.isLoggedIn) {
        if (this.globalsVariablesService.getPermissaoSuccess) {
          this.usuarioAutenticado();
        } else {
          this.recuperarSessao();
          this.usuarioAutenticado();
        }
      }
    } else {
      if (validAuth) this.verificarAutenticacao(); else this.resetSessao();
    }
  }

  private usuarioAutenticado() {
    // Caso entre em varias telas de login e realize o login em uma delas
    // as outras telas devem obedecer autenticação e atualizar a quantidade de páginas ativas
    // sendo o usuário redirecionado ao Dashboard.
    let pagesActive = localStorage.getItem("PagesActive");
    this.pagesActive = parseInt(pagesActive) + 1;
    this.limitPage(this.pagesActive);
    localStorage.setItem("PagesActive", this.pagesActive.toString());
    document.querySelector('body').classList.remove('backLogin');
    this._router.navigate(['/dashboard']);
  }

  private recuperarSessao() {
    //CASO TIVER AUTENTICAÇÃO VOLTA COM AS PERMISSÕES DO USUÁRIO
    this.user = this.cookieService.get('user');
    sessionStorage.removeItem("usuarioLogado");
    localStorage.removeItem("usuarioLogado");
    let login = JSON.parse(this.user);
    this.authService.autenticarUsuario(login).subscribe(res => {
      this.usuarioLogado = res;
     
      this.globalsVariablesService.setGlobalsVariables(this.usuarioLogado);
    });

  }

  private resetSessao() {
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem("PagesActive", "1");
    this.cookieService.delete('user');
    this.globalsVariablesService.blockedGlobalsVariables();
    this._router.navigate(['/login']);
  }

  private logout() {
    this._confirmationService.confirm({
      message: 'Tem certeza que deseja sair do sistema ?',
      header: 'Àgape Cloud',
      icon: 'fa fa-power-off',
      accept: () => {
        this.resetSessao();
        this.authService.logout();
      }
    });
  }


  private saveCurrentRoute() {
    let rotaAtual = this._router.url;
    sessionStorage.setItem("rota", rotaAtual);
  }

  private getRoute() {
    return sessionStorage.getItem("rota");
  }

  private limitPage(pages) {
    if (pages >= this.limitePaginas) {
      this._messageService.warn('Prezado(a), você atingiu o limite máximo de 4 páginas ativas. Por gentileza, não ultrapasse-o.');
      setTimeout(function () {
        location.replace("https://araguaiasistemas.com.br");
      }, 5000);
    }
  }

  public verificarRotas() {
    this._router.events
      .subscribe((event) => {
        if (event instanceof ActivatedRoute) {
          this.rotaAtual = event.url;
          /*
          if(this.rotaAtual === "/comercial/pedido/new"){
            this.rotaAnterior = event.url;
            this.authService.setCancelRoute(true);
          }else{
            if(this.rotaAnterior === "/comercial/pedido/new"){
              if(this.rotaAtual != "/comercial/pedido"){
                this._confirmationService.confirm({
                  message: 'Atenção! Os dados serão perdidos. Deseja realmente mudar de tela ?',
                  header: 'Aviso',
                  icon: 'fa fa-exclamation-triangle',
                  accept: () => {
                    this.rotaAnterior = this.rotaAtual;
                    this.authService.setCancelRoute(false);
                    this._router.navigate([this.rotaAtual]);
                  }
                });
              }
            }
          }*/
        }
      });
  }

  public listarSetups() {
    this._setupService.getSetup().then(res => {
      this.carregarInformacoesSetup(res);
    });
  }

  public carregarInformacoesSetup(res) {
    this.globalsVariablesService.setInformacoesSetup(res);
  }

  @HostListener("window:keydown", ["$event"])
  detectKeyboard(event: KeyboardEvent) {
    if (event.keyCode == KEY_CODE.TECLA_F05) {
      this.keypressCode = event.keyCode;
    }

    if (event.keyCode === KEY_CODE.TECLA_J && event.ctrlKey == true) {
      //prevent event Crlt + J (Downloads) p/ leitores de código de barras
      event.preventDefault();
    }

    if (event.keyCode === KEY_CODE.TECLA_F12) {
      //prevent event Inspecionar Elemento p/ leitores de código de barras
      event.preventDefault();
    }
  }

  @HostListener('window:unload', ['$event'])
  unload(event) {
    //event.preventEvent(); //quando acionado o restante do código não é lido pelo navegador
    let pagesActive = parseInt(localStorage.getItem("PagesActive"));
    //só pode diminuir o número página ativa se já tiver carregado ela toda
    if (this.pageEnter) pagesActive--;
    this.pagesActive = pagesActive;
    localStorage.setItem("PagesActive", pagesActive.toString());

    //SALVA OS DADOS DE PERMISSAO DO USUÁRIO PARA QUANDO DADO UM REFRESH
    sessionStorage.setItem("usuarioLogado", JSON.stringify(this.globalsVariablesService.getUsuarioLogado));

    if (this.pagesActive <= 0) {
      if (this.keypressCode != KEY_CODE.TECLA_F05) {
        //DADOS PARA RECUPERAR EM UM EVENTO RELOAD (REFRESH DA ÚLTIMA PÁGINA)
        this.saveCurrentRoute();
        // -------------------------------------------------------------- //
        this.authService.logout();
        localStorage.clear();
      }
    }
  }

  /**
   * Fica verificando se haverá algum clique no botão SALVAR,
   * se houver, imediatamente após o click, o botão SALVAR é desabilitado
   * evitando que quando houver um delay no processo "SAVE" o usuário clique novamente
   * em salvar e salve o registro 2x
   */
  @HostListener('click', ['$event.target.classList']) onClick(event) {
    let arrClass = event;
    let result = arrClass.contains('btnSalvar');
    this.windowSize = window.innerWidth;

    if (this.windowSize < 500) {
      let mobile = arrClass.contains('rota');
      if (mobile) {
        document.querySelector('.sidebar-mini').classList.remove('sidebar-open');
      }
    }

    /* EVITA DUPLOS CLIQUES EM SALVAR
    * Retirei para que seja reativo o seu comportamento
    *  
    if(result){
      document.querySelector('.btnSalvar').setAttribute("disabled", "disabled");
    }*/

    this.verificarLogin();
  }

  public buscarSubMenus(): void {
    let interval = setInterval(() => {
      this.subMenus = document.querySelectorAll('.menuSelecionado');
      let arraySubMenus = Array.prototype.slice.call(this.subMenus);
      if (arraySubMenus.length > 20) {
        this.adicionarEventoClique(arraySubMenus);
        clearInterval(interval);
      }

    }, 2000)
  }

  public adicionarEventoClique(arraySubMenus: any[]): void {
    arraySubMenus.forEach(elemento => {
      elemento.addEventListener('click', () => {
        if (elemento == this.subMenuAnterior)
          return;

        elemento.classList.add('subMenuAtivo');
        if (this.subMenuAnterior != null)
          this.subMenuAnterior.classList.remove('subMenuAtivo');

        this.subMenuAnterior = elemento;
      });
    })
  }

}
  <div id="containerBreadcrumb">
    <section class="content-header">
      <div class="icon-breadcrumb">
        <i class="fi-dashboard-graphic"></i>
      </div>
      <h1>
        Dashboard
        <small>{{version}}</small>
      </h1>
      <ol class="breadcrumb">
        <li>
          <form [formGroup]="filtro" class="dashboard-date-filter">
            <p class="data"> Período: </p>
            <p-calendar
              class="calendar-filter"
              formControlName="periodo"
              selectionMode="range"
              dateFormat="dd/mm/yy"
              (onSelect)="onSelectDate()"
              [locale]="pt_BR"
              required
            >
            </p-calendar>
          </form>
        </li>
        <li><a href="#"> Home</a></li>
        <li class="active">Dashboard</li>
      </ol>
    </section>
  </div>



      <div class="row dashboard-panels">

        <div *ngIf="globalsVariablesService.getPermissoes.visualizarDashboard">
             <!-- ./col -->
          <div class="ui-sm-6 ui-md-6 ui-lg-4">
            <!-- small box -->
            <div class="small-box bg-yellow">
              <div class="inner">
                <!-- <h3>{{!grupoLojaId ? numMembros : 0}}</h3> -->
                <h3>{{numMembros}}</h3>

                <p>Membros</p>
              </div>
              <div class="icon">
                <i class="fi-ecommerce-shopping-basket"></i>
              </div>
              <a routerLink="/relatorios/rel-vendas" class="small-box-footer">Mais informações <i class="fi-interface-right-arrow"></i></a>
            </div>
          </div>
        <!-- ./col -->
        <!-- ./col -->
          <div class="ui-sm-6 ui-md-6 ui-lg-4">
            <!-- small box -->
            <div class="small-box bg-red">
              <div class="inner">
                <!-- <h3>{{!grupoLojaId ? (valorContasAPagar | number:'1.2-2') : 0}}</h3> -->
                    <h3>{{valorContasAPagar | number:'1.2-2'}}</h3>
                <p>A Pagar</p>
              </div>
              <div class="icon">
                <i class="fi-ecommerce-coins"></i>
              </div>
              <a routerLink="/relatorios/rel-duplicata-pagar" class="small-box-footer">Mais informações <i class="fi-interface-right-arrow"></i></a>
            </div>
          </div>

          <div class="ui-sm-6 ui-md-6 ui-lg-4">
            <!-- small box -->
            <div class="small-box bg-aqua">
              <div class="inner">
                <!-- <h3>{{grupoLojaId ? ( valorContasAReceber | number:'1.2-2') : 0}}</h3> -->
                <h3>{{valorContasAReceber | number:'1.2-2'}}</h3>
                <p>Dízimos</p>
              </div>
              <div class="icon">
                <i class="fi-dashboard-pie-graphic"></i>
              </div>
              <a routerLink="/relatorios/rel-compra" class="small-box-footer">Mais informações <i class="fi-interface-right-arrow"></i></a>
            </div>
          </div>
      </div>



    <!-- <section class="ui-sm-12 ui-md-12 ui-lg-6 connectedSortable ui-sortable"> -->
      <!-- <div class="graphs-custom nav-tabs-custom" >
        <ul class="nav nav-tabs pull-right ui-sortable-handle">
          <li class="active">
            <a href="#chart-one" data-toggle="tab">Mensal</a>
          </li>
          <li>
            <a href="#chart-two" data-toggle="tab">Anual</a>
          </li>
          <li class="pull-left header">
            <i class="fi-ecommerce-shopping-basket"></i> Dizimos
          </li>
        </ul>
        <div class="tab-content no-padding">
          <div class="chart tab-pane active" id="chart-one" style="position: relative; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
            <p-chart type="line" [data]="vendasMensal"></p-chart>
          </div>
          <div class="chart tab-pane" id="chart-two" style="position: relative; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
            <p-chart type="bar" [data]="vendasAnual"></p-chart>
          </div>
        </div>
      </div>
      <div class="graphs-custom box box-solid">
        <div class="box-header ui-sortable-handle">
          <i class="fi-ecommerce-money"></i>
          <h3 class="box-title"> Crescimento Mensal </h3>
        </div>
        <div class="box-body border-radius-none">
          <p-chart type="horizontalBar" [data]="financeiro"></p-chart>
        </div>
      </div>
    </section> -->

    <!-- <section class="ui-sm-12 ui-md-12 ui-lg-6  connectedSortable ui-sortable">
      <div class="graphs-custom box box-solid bg-teal-gradient">
        <div class="box-header ui-sortable-handle">
          <i class="fi-interface-password"></i>
          <h3 class="box-title"> Saldo das Contas </h3>
        </div>
        <div class="box-body border-radius-none">
          <p-chart type="line" [data]="compras"></p-chart>
        </div>
      </div>
      <div class="graphs-custom box box-warning">
        <div class="box-header ui-sortable-handle" >
          <i class="fi-ecommerce-like-1"></i>
          <h3 class="box-title">Últimas Notificações</h3>
        </div>
          <div class="box-body">
            <ul class="todo-list ui-sortable">
              <li class="" style="">
                <span class="handle ui-sortable-handle">
                  <i class="fa fa-ellipsis-v"></i>
                  <i class="fa fa-ellipsis-v"></i>
                </span>
                <span class="text">Aniversáriantes</span>
                <small class="label label-danger"><i class="fa fa-clock-o"></i> 2 min</small>
                <div class="tools">
                  <i class="fa fa-edit"></i>
                  <i class="fa fa-trash-o"></i>
                </div>
              </li>
              <li>
                <span class="handle ui-sortable-handle">
                  <i class="fa fa-ellipsis-v"></i>
                  <i class="fa fa-ellipsis-v"></i>
                </span>
                <span class="text">Culto Domingo as 19:00 !</span>
                <small class="label label-info"><i class="fa fa-clock-o"></i> 4 horas</small>
                <div class="tools">
                  <i class="fa fa-edit"></i>
                  <i class="fa fa-trash-o"></i>
                </div>
              </li>
              <li>
                <span class="handle ui-sortable-handle">
                  <i class="fa fa-ellipsis-v"></i>
                  <i class="fa fa-ellipsis-v"></i>
                </span>
                <span class="text">Contas a Pagar </span>
                <small class="label label-success"><i class="fa fa-clock-o"></i> 3 dia(s)</small>
                <div class="tools">
                  <i class="fa fa-edit"></i>
                  <i class="fa fa-trash-o"></i>
                </div>
              </li>
              <li>
                <span class="handle ui-sortable-handle">
                  <i class="fa fa-ellipsis-v"></i>
                  <i class="fa fa-ellipsis-v"></i>
                </span>
                <span class="text">Atualizar Cadastros</span>
                <small class="label label-default"><i class="fa fa-clock-o"></i> 10 dia(s)</small>
                <div class="tools">
                  <i class="fa fa-edit"></i>
                  <i class="fa fa-trash-o"></i>
                </div>
              </li>
            </ul>
          </div>
        <div class="box-footer clearfix no-border">
          <button type="button" class="btn btn-default bg-aqua pull-right"><i class="fa fa-plus"></i> Adicionar Notificação</button>
        </div>
      </div>

    </section> -->
  </div>

import {GenericModel} from '../../shared/model/generic.model';

export class Usuario extends GenericModel {
    
    public id: number;
    public pessoaId: number;    
    public pessoaNomeFantasia: string = '';
    public pessoaRazaoSocial: string = ''; 
    
    public pessoaCnpjCpf: string;    

    public pessoaIdentidade: string;
    public pessoaOrgaoExpedidor: string;
    public pessoaDataNascimento: Date;
    
    public pessoaEnderecos: Array<any>;
    public pessoaTelefones: Array<any>;
    public pessoaEnderecosWeb: Array<any>;

    public lojaId:number;
    public lojaPessoaNomeFantasia:string;

    public permissaoId: number;
    public permissaoDescricao: string;

    public grupoLojaId: number;   
    public grupoLojaDescricao:string;
    public caminhoImagem :string;
    public nomeImagem    :string;
    public tamanhoImagem :number;

    public login: string;
    public senha: string;    

    public token:string;
    public colaboradorId: number;   
    public colaboradorPessoaNomeFantasia:string;
    

}
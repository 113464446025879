
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';


import { VsCrudService } from '../../shared/service/vs-crud.service';
import { MessageService } from '../../shared/service/message.service';
import { PageResult, SearchParams } from '../../shared/service/generic.service';

import { Favorecido } from './favorecido.model';
import { ImportacaoXLS } from '../../configuracoes/importacao-xls/importacao-xls.model';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class FavorecidoService extends VsCrudService<Favorecido> {

  public url: string = "api/favorecidos";

  pageResult: PageResult = new PageResult();

  public urlEndereco: string = "http://ws.hubdodesenvolvedor.com.br/cep/?cep=74425010&token=678028218395277ABV4950863602.5";

  private httpCepHttp;

  constructor(_http: HttpClient, _message: MessageService) {
    super(<any>_http, _message);
  }

  /*getEndereco() {
    let requestOptions: RequestOptions = this.getRequestCEP();
    return this.httpCepHttp.get(this.urlEndereco, this.getRequestCEP).map(res => <any> res);

  }*/

  /*private getRequestCEP(): RequestOptions {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        //headers.append('Access-Control-Allow-Credentials', `true`);
        return new RequestOptions({headers: headers});
  }*/

     /**
     * Listar varios registros
     */
    public listarMembrosCompleto(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchMembroCompleto', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public listPersonalizado(search: SearchParams) {

      let self = this;
      return this.search(this.url + '/searchPersonalizado', search)
          .subscribe(res => {
              self.pageResult = res;
              console.log(res);
          });
    }


    public listMembroPersonalizado(search: SearchParams) {

      let self = this;
      return this.search(this.url + '/searchMembroPersonalizado', search)
          .subscribe(res => {
              console.log(res)
              self.pageResult = res;
          });
    }

    public countMembros(search: SearchParams) {

      return this.search(this.url + '/countMembros', search)
         
    }

    public findByCnpj(cnpj: string){
      let self = this;
      return this.get(`${this.url}/findByCnpj/${cnpj}`).toPromise()
      .then(res =><Favorecido>res);
    }

    public findFornecedorByCnpj(cnpj: string){
      let self = this;
    //  return this.get(`${this.url}/findFornecedorByCnpj/${cnpj}`).toPromise()
     // .then(res =><Favorecido>res);
     return this.get(`${this.url}/findFornecedorByCnpj/${cnpj}`).pipe(map(res => <Favorecido>res));
    }

    public findByCnpjObservable(cnpj: string){
      return this.get(`${this.url}/findByCnpj/${cnpj}`).pipe(map(res => <Favorecido>res));

    }

    public importarArquivoFornecedorXLS(arquivo: string) {
      return this.get(`${this.url}/importarXLS/${arquivo}`)
                     .toPromise()
                     .then(res =><Boolean>res);

    }

    public importarArquivoMembroXLS(arquivo: string) {
      return this.get(`${this.url}/importarMembroXLS/${arquivo}`)
                     .toPromise()
                     .then(res =><Boolean>res);

    }

    public importarArquivoMinisterioCargoXLS(arquivo: string) {
      return this.get(`${this.url}/importarMinisterioCargoXLS/${arquivo}`)
                     .toPromise()
                     .then(res =><Boolean>res);

    }

    public importarArquivoProfissaoXLS(arquivo: string) {
      return this.get(`${this.url}/importarProfissaoXLS/${arquivo}`)
                     .toPromise()
                     .then(res =><Boolean>res);

    }

    public importarArquivoFamiliaresXLS(arquivo: string) {
      return this.get(`${this.url}/importarFamiliaresXLS/${arquivo}`)
                     .toPromise()
                     .then(res =><Boolean>res);

    }

    public importarArquivoLiderancaXLS(arquivo: string) {
      return this.get(`${this.url}/importarLiderancaXLS/${arquivo}`)
                     .toPromise()
                     .then(res =><Boolean>res);

    }

    public importarAlterarGrupoMembroXLS(arquivo: string) {
      return this.get(`${this.url}/importarAlterarGrupoXLS/${arquivo}`)
                     .toPromise()
                     .then(res =><Boolean>res);

    }


    public findByCodigoInterno(codigo: Number) {
      return this.get(`${this.url}/interno/${codigo}`).pipe(map(res => <Favorecido>res));
    }



}


